/* BottomNavigation.scss */

/* Always show scrollbar for WebKit browsers (Chrome, Safari, Edge) */
.scrollable-content {
  width: 100%;
  // max-height: 290px;
  overflow-y: scroll; /* Always show vertical scrollbar */
  
  /* WebKit-specific scrollbar styling */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #c4c4c4 transparent; /* For Firefox */

  /* For WebKit browsers */
}

.scrollable-content::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.scrollable-content::-webkit-scrollbar-track {
  background: transparent; /* Background of the scrollbar track */
}

.scrollable-content::-webkit-scrollbar-thumb {
  background-color: #c4c4c4; /* Color of the scrollbar thumb */
  border-radius: 4px; /* Roundness of the scrollbar thumb */
  border: 2px solid transparent; /* Padding around the scrollbar thumb */
}

.scrollable-content::-webkit-scrollbar-thumb:hover {
  background-color: #c4c4c4; /* Color of the scrollbar thumb on hover */
}