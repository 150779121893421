.ant-select-selector {
  height: 40px;
}

.submit-btn {
  position: fixed;
  bottom: 0;
  border-radius: 0;
  height: 48px;
  font-size: 16px;
  // max-width: 420px;
  max-width: 720px;
  margin-left: -1px;
}