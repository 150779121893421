.ant-menu-title-content {
  display: contents;
}

.input-number-w-button-after .ant-input-number-group-addon {
  padding: 0;
  background-color: #4BBF9F;
}

.input-number-w-button-after .ant-input-number-input {
  padding-right: 40px !important;
  text-align: end;
}

.input-number-w-button-after .ant-input-number-wrapper-disabled>.ant-input-number-group-addon {
  background-color: #98A2B3 !important;
  border-color: #98A2B3 !important;
}
.input-number-w-button-after .ant-input-number-wrapper-disabled>.ant-input-number-group-addon button {
  background-color: #98A2B3 !important;
  border-color: #98A2B3 !important;
}