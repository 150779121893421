.onboarding-page {
  position: relative;
  min-height: 98vh;

  .onboarding-header {
    padding: 25px 15px;
    position: fixed;
    z-index: 9999999;
  }

  .onboarding-steps-container {
    height: calc(98vh - 62px);
    display: flex;
    justify-content: center;
    // align-items: center;
    
    .content {
      position: relative;
      // margin-top: -64px;
    }
  }

  .onboarding-steps {
    padding: 15px 20px;
    position: fixed;
    bottom: 0px;
    width: 100%;
    max-width: 719px;
    border: 1px solid #d0d5dd;
    border-left: 0px;
    // z-index: 10000001 !important;
    background-color: #ffff;
  }
  .steps {
    .inactive {
      /* Ellipse 4 */

      width: 6px;
      height: 6px;

      /* Gray/300 */
      background: #d0d5dd;

      /* Inside auto layout */
      flex: none;
      order: 1;
      flex-grow: 0;
      border-radius: 100%;
    }
    .current {
      /* Rectangle 59 */

      width: 18px;
      height: 6px;

      background: #3431D6;
      border-radius: 3px;

      /* Inside auto layout */
      flex: none;
      order: 0;
      flex-grow: 0;
    }
    .active {
      /* Ellipse 4 */

      width: 6px;
      height: 6px;

      background: #3431D6;

      /* Inside auto layout */
      flex: none;
      order: 0;
      flex-grow: 0;
      border-radius: 100%;
    }
  }
}

.onboarding-list {
  .ant-list-item {
    border-bottom: 0px !important;
    border: 1px solid #98A2B3 !important;
    padding: 10px 10px 5px 10px;
    margin-bottom: 10px;
    border-radius: 4px;
  }
}