@font-face {
  font-family: 'PretendardVariable';
  src: local("PretendardVariable"), url('./assets/fonts/PretendardVariable.ttf') format('truetype');
  /* Add additional font formats if necessary */
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* max-width: 600px;
  margin: 0 auto; */
}

.font-face-pv {
  font-family: "PretendardVariable";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

ol, ul {
  padding-left: 20px;
}