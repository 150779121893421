
.title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;
  color: #101828;
}

.text-red {
  color: #F63D68;
}

.text-yellow {
  color: #EAAA08;
}

.text-green {
  color: #4BBF9F;
}

.notice-float-btn{
  position: fixed;
  bottom: 70px;
  background-color: #F63D68;
  border-radius: 18px;
  width: 126px;
  height: 36px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.product-info-space {
  width: 100%;
  .ant-space-item {
    width: 100%;
  }
}



.business-onboarding {
  .ant-modal-content {
    padding: 15px;
    border-radius: 5px;
  }
}