.loginPage{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  // padding-top: 20%;
  .content{
    width: 375px;
    padding: 0 24px 0 24px ;
    .logo{
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
    }
  }

}

.isValidInfo {
  background-color: #4BBF9F;
  color:#FFFFFF;
  border:1px solid #4BBF9F;
}

.ant-drawer-close {
  position: absolute;
  top: 20px;
  right: 0;
}
