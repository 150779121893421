.ant-message-notice-error {
  .ant-message-notice-content {
    width: 95%;
    background-color: #FEA3B4 !important;
    font-size: 14px;
    font-weight: 600;
    color: #101828;
    text-align: left;
    border-radius: 4px !important;
    margin-top: 60px;
    .anticon-close-circle {
      display: none;
    }
  }
}
