.ant-menu-light .ant-menu-item-selected {
  background-color: #84888c69;
}
.ant-modal-close {
  right: 22px;
}

.ant-input-number .ant-input-number-input {
  /* color: unset; */
}

.custom-carousel .slick-dots-none {
  display: none !important;
}

.ant-input-disabled .ant-input[disabled] {
  color: #98A2B3;
  background-color: #EAECF0;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}

.ant-input-number-disabled .ant-input-number-input{
  color: #98A2B3;
  background-color: #EAECF0;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}

.ant-picker .ant-picker-input >input[disabled]{
  color: #98A2B3;
  background-color: #EAECF0;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}

.ant-btn-default {
  /* border-color: #10264D; */
}

.ant-card-bordered .ant-card-cover {
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.ant-select-dropdown > div > div:nth-child(2) > div:nth-child(2) > div {
  background-color: transparent !important;
}
.ant-select-dropdown > div > div:nth-child(2) > div:nth-child(1)  {
  overflow-y: auto !important;
  overscroll-behavior-y: contain;
}
.ant-form-item{
  margin-bottom: 8px;
}
.ant-form-item .ant-form-item-label{
  padding: 0px;
}
.ant-image-preview-img-wrapper{
  background-color: black;
}
.ant-btn-primary{
  box-shadow: unset;
}

.ant-radio-wrapper:hover .ant-radio-wrapper, .ant-radio-wrapper:hover .ant-radio-inner{
  border-color: #667085;
}
.ant-radio-wrapper .ant-radio-inner{
  border-color: #667085;
}
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner{
  background-color: #4BBF9FF2;
  border-color: #4BBF9FF2;
}

/* .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #4BBF9FF2 !important;
  border-color: #4BBF9FF2 !important;
} */

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #3431D6 !important;
  border-color: #3431D6 !important;
}

.ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
  display: none
}
.ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after{
  display: inline-block;
  margin-inline-end: 4px;
  color: #f63d68;
  font-size: 14px;
  font-family: SimSun,sans-serif;
  line-height: 1;
  content: "*";
  margin-left: 4px;
}

.ant-btn-custom-disabled:disabled {
  cursor: not-allowed;
    border-color: #d9d9d9 !important;
    color: #98A2B3;
    background-color: #EAECF0 !important;
    box-shadow: none;
}
