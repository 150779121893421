.upload-custom {
  .ant-upload-select {
    width: 100%;
  }
  .ant-upload-disabled {
    background-color: #EAECF0;
    .ant-card-bordered {
      background-color: #EAECF0;
    }
  }
}

.list-file {
  display: flex;
  margin-top: 20px;
  width: 100%;
  overflow-y: scroll;

  .file-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 8px;
  }
}
.progress-upload{
  .ant-model-content{
    padding: 8px 10px;
  }
}