.tabs {
  display: flex;
  width: 100%;
  justify-content: center;
  // box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.15);
  margin: 26px 0 16px 0;

  .item {
    border: 1px solid #D0D5DD;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

    &:first-of-type {
      border-radius: 8px 0 0 8px;
    }

    &:last-of-type {
      border-radius: 0 8px 8px 0
    }
  }

  .selected {
    background-color: #10264D;
    color: #fff;
  }
}

.ant-segmented-group {
  .ant-segmented-item-label {
    margin: 5px 0;
  }
  .ant-segmented-item-selected:first-child {
    background-color: #F2F4F7;
    color: #fff;
    .ant-segmented-item-label {
      border-radius: 4px;
      background-color: #10264D;
      margin-left: 1rem;
    }
  }
  .ant-segmented-item-selected:last-child {
    background-color: #F2F4F7;
    color: #fff;
    .ant-segmented-item-label {
      border-radius: 4px;
      background-color: #10264D;
      margin-right: 1rem;
    }
  }
}

.submit-new-btn {
  position: fixed;;
  bottom: 0;
  border-radius: 0;
  height: 48px;
  font-size: 16px;
  // max-width: 420px;
  max-width: 720px;
  margin-left: -1px;
}

.create-new-request {
  .next-btn {
    position: fixed;
    height: 48px;
    bottom: 0;
    border-radius: 0;
    // max-width: 420px;
    max-width: 720px;
  }
  .ant-form-item-label {
    label {
      width: 100%;
    }
  }
}

.payment-info{
  height: 85vh;
  padding: 0 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.request-completed {
  .ant-collapse-content-active {
    border-top: 0;
  }
}
.notice-modal {
  .ant-modal-title {
    font-size: 16px;
    text-align: center;
    padding-top: 24px;
  }
  .ant-modal-content {
    padding: 0;
    .notice-modal-content {
      padding: 0 24px 24px;
      text-align: center;
      font-size: 14px;
    }
  }
}