svg {
  vertical-align: baseline;
}
.container-page {
  padding: 0 16px 0 16px;
  height: 100%;
}

.fixed-bottom-btn {
  position: fixed;
  height: 48px;
  bottom: 0;
  border-radius: 0;
  /* max-width: 420px; */
  max-width: 720px;
  /* margin: 0 -16px 0 -16px; */
}

.fixed-bottom-container {
  width: 100%;
  padding: 16px 16px 0px 16px;
  background-color: #fff;
  position: fixed;
  height: 90px;
  bottom: 60px;
  max-width: 718px;
  border-top: 1px solid #D0D5DD;
}

.btn-bottom-container {
  width: 100%;
  padding: 16px 16px 0px 16px;
  background-color: #fff;
  height: 90px;
  bottom: 60px;
  max-width: 718px;
  border-top: 1px solid #D0D5DD;
}

.btn-bottom-container button,
.fixed-bottom-container button {
  width: 100%;
  height: 48px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 700;
  border: none;
}

.text-20{
  font-size: 20px;
  font-weight: 700;
  color: #344054;
  margin-bottom: 4px;
}
.text-12{
  font-size: 12px;
  font-weight: 400;
  color: #667085;
  margin-bottom: 4px;
}
.text-14{
  font-size: 14px;
  font-weight: 400;
  color: #667085;
  margin-bottom: 4px;
}
.text-16{
  font-size: 16px;
  font-weight: 700;
  color: #344054;
  margin-bottom: 4px;
}
.text-18{
  font-weight: 700;
  font-size: 18px;
  color: #101828;
  margin-bottom: 4px;
}
.text-20{
  font-weight: 700;
  font-size: 20px;
  color: #101828;
  margin-bottom: 4px;
}
.gray-900{
  color: #101828;
}
.gray-700{
  color: #344054;
}

.fw-6{
  font-weight: 600;
}
.fw-7{
  font-weight: 700;
}
.fw-4{
  font-weight: 400;
}
.fw-5{
  font-weight: 500;
}
.green-btn {
  background-color: #4BBF9F;
  color: #fff;
  
}
.green-btn:hover{
  background-color: rgba(75, 191, 158, 0.767) !important;
}

.readonly-checkbox .ant-checkbox-disabled .ant-checkbox-inner {
  border-color: currentColor;
  background-color: #fff;
}
.readonly-checkbox .ant-checkbox-disabled+span {
  width: 100%;
}

/* responsive */
@media (min-width: 720px) {
  .container {
      width: 100%;
      max-width: 720px;
      margin: 0 auto;
  }

  /* Add more styles as needed to adjust layout for larger screens */
  .header, .footer, .content {
      padding: 20px;
  }

  .sidebar {
      display: block;
      width: 200px;
  }

  .main-content {
      margin-left: 220px;
  }

  .ant-drawer-content-wrapper {
    max-width: 720px !important;
    margin: 0 calc(50% - 360px);
  }
}