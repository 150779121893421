.htv-layout {
  background-color:  transparent;
  .ant-menu-item-group-title {
    padding: 8px 0px;
  }
  ul.ant-menu-item-group-list {
    display: flex;
    flex-flow: row wrap;
    li {
      background-color: #FFF;
    }
    .ant-menu-item-danger {
      color: #000;
      background-color: #FFF1F3 !important;
    }
    .ant-menu-item-selected {
      background-color: #84888c69 !important;
    }
  }
  .ant-menu-item {
    padding: 10px 12px !important;
    width: calc(50% - 8px) !important;
    border-radius: 5px;
  }
  .ant-menu-title-content {
    display: flex;
    justify-content: space-between;
    p {
      margin: 0px;
      span {
        font-size: 20px;
        margin-right: 3px;
      }
    }
  }
  .home-btn {
    .ant-btn-icon {
      line-height: 3.5 !important;
    }
  }
}

.adm-tab-bar-item-icon {
  font-size: 20px !important;
}

.adm-tab-bar-item-title {
  font-size: 12px !important;
}

.ant-drawer-body {
  padding-left: 16px !important;
  padding-right: 16px !important;
}